import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { TickerTapService } from '../ticker-tap/ticker-tap.service';

export enum MessageScheduleType {
  StartMessage = 1,
  EndMessage = 2
}

@Injectable({
  providedIn: 'root'
})
export class SignalrService {

  private hubConnection: signalR.HubConnection;

  constructor(private tickerTapService: TickerTapService) {
  }

  public startConnection() {
    this.hubConnection = new signalR.HubConnectionBuilder()
                         .withAutomaticReconnect([0])
                         .withUrl(environment.apiUrl.signalR + "/scheduleMessage", {skipNegotiation: true, transport: signalR.HttpTransportType.WebSockets })
                         .build();
    this.hubConnection
      .start()
      .then(() =>  {
        this.hubConnection.serverTimeoutInMilliseconds = 100000; // 100 second
        console.log("Connection started.")
      })
      .catch(err =>  {
        console.log("Error while starting connection: " + err)})
  }

  checkSignalRState() {
    return this.hubConnection.state;
  }

  public messageActivateListener() {
    if(this.hubConnection != null && this.hubConnection != undefined) {
      this.hubConnection.on("notifyMessageActivate", data => {
        this.updateScheduleMessageOnHeader(data, MessageScheduleType.StartMessage);
      });
    } else {
      console.log("ActivateListener: signalr not connected yet.");
    }
  }

  public messageExpiredListener() {
    if(this.hubConnection != null && this.hubConnection != undefined) {
      this.hubConnection.on("notifyMessageExpired", data => {
        this.updateScheduleMessageOnHeader(data, MessageScheduleType.EndMessage);
      });
    } else {
      console.log("MessageExpired: signalr not connected yet.");
    }
  }

  private updateScheduleMessageOnHeader(data, messageType: MessageScheduleType) {
    if (data != null) {
      let isPresent = this.tickerTapService.scheduleMessages.find(x => x.tickerTapId == data.tickerTapId);

      if (!isPresent && MessageScheduleType.StartMessage) {
        if (moment(data.endDateTime).format('YYYY-MM-DD HH:mm') > moment().utc().format('YYYY-MM-DD HH:mm')) {
          this.tickerTapService.scheduleMessages.push({tickerTapId: data.tickerTapId, startDateTime: data.startDateTime, endDateTime: data.endDateTime, message: '<p class="marqee-mark-p">' + data.message + '</p>', htmlMessage: ''});
        }
      }
      else {
        this.tickerTapService.scheduleMessages.map((message, index) => {
          if (message.tickerTapId == data.tickerTapId) {
            if (messageType == MessageScheduleType.StartMessage) {
              this.tickerTapService.scheduleMessages[index] = {tickerTapId: data.tickerTapId, startDateTime: data.startDateTime, endDateTime: data.endDateTime, message: '<p class="marqee-mark-p">' + data.message + '</p>', htmlMessage: ''};
            }
            else if (messageType == MessageScheduleType.EndMessage) {
              this.tickerTapService.scheduleMessages = this.tickerTapService.scheduleMessages.filter(x => x.tickerTapId != data.tickerTapId);
            }
          }
        });
      }
    }
  }
}

