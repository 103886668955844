import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
/******************* */
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { StoreModule } from '@ngrx/store';
//---------------Services-------------------//
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CookieService } from 'ngx-cookie-service';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastNotificationsModule } from 'ngx-toast-notifications';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomToastComponent } from './components/custom-toast/custom-toast.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HeaderComponent } from './components/header/header.component';
import { LandingComponent } from './components/landing/landing/landing.component';
import { LeftnavmenuComponent } from './components/leftnavmenu/leftnavmenu.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { NumberOnlyDirective } from './directives/numberonly.directive';
import { OneDigitDecimalNumberDirective } from './directives/onedigitdecimalnumber.directive';
import { TooltipDirective } from './directives/tooltip.directive';
import { TwoDigitDecimalNumberDirective } from './directives/twodigitdecimalnumber.directive';
import { JwtInterceptor } from './helper/jwt.interceptor';
import AlarmTemplateAddCanDeactivateGuard from './services/alarm-template/alarm-deactivate.guard';
import { AuthService } from './services/auth/auth.service';
import { EntityService } from './services/entity/entity.service';
import { NotificationService } from './services/notification/notification.service';
import { NotificationCountService } from './services/notification/notificationCountService.service';
import { FilterPipe } from './filter.pipe';
import { NgMarqueeModule } from 'ng-marquee';
import { RoleService } from './services/role/role.service';
import UserAuditCanDeactivateGuard from './services/user-audit/user-audit-deactivate.guard';
import { machineListFilterReducer } from './store/machine-list-filter/machine-list-filter.reducer';
import { FooterModule } from './components/footer/footer.module';
import { DialogTemplatesModule } from './components/common/dialog-templates.module';
import { CostaCoffeeLibModule } from '@costa-coffee/pattern-lib';

export const protectedResourceMap: [string, string[]][] = [[environment.msal.costaExpressUrl, [environment.msal.apiscope]]];

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msal.clientId,
      authority: environment.msal.authority,
      redirectUri: environment.msal.redirectUrl,
      postLogoutRedirectUri: environment.msal.postLogoutRedirectUri,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.msal.costaExpressUrl, [environment.msal.apiscope]); // Prod environment. Uncomment to use.

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [environment.msal.loginscope],
    },
  };
}

export function HttpLoaderFactory(httpClient: HttpClient) {
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    LeftnavmenuComponent,
    TooltipDirective,
    TwoDigitDecimalNumberDirective,
    OneDigitDecimalNumberDirective,
    NumberOnlyDirective,
    LandingComponent,
    PageNotFoundComponent,
    FilterPipe,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    NgMultiSelectDropDownModule.forRoot(),
    BrowserAnimationsModule,
    NgxSpinnerModule,
    AppRoutingModule,
    MatButtonModule,
    HttpClientModule,
    MatDialogModule,
    ToastNotificationsModule.forRoot({ component: CustomToastComponent }),
    MalihuScrollbarModule.forRoot(),
    StoreModule.forRoot({ machineListFilter: machineListFilterReducer }, {}),
    FooterModule,
    NgMarqueeModule,
    DialogTemplatesModule,
    CostaCoffeeLibModule,
  ],
  exports: [NgScrollbarModule, TooltipDirective, TwoDigitDecimalNumberDirective, OneDigitDecimalNumberDirective, NumberOnlyDirective],
  providers: [
    AuthService,
    EntityService,
    CookieService,
    RoleService,
    NotificationService,
    NotificationCountService,
    UserAuditCanDeactivateGuard,
    AlarmTemplateAddCanDeactivateGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
