import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective {

  @Input('appTooltip') toolTipTitle: string;
  @Input() placement: string;
  @Input() delay: number;
  tooltip: HTMLElement;
  offset = 10;

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2
  ) { 
   
  }

  // event listeners for tooltip
  @HostListener('load') OnLoad() {
    if(this.tooltip)
      $('.ng-tooltip').removeClass('ng-tooltip-show');
  }
  @HostListener('mouseenter') onMouseEnter() {
    this.showTooltip();      
    
  }
  @HostListener('mouseleave') onMouseLeave() {    
      this.hideTooltip();
  }  
  @HostListener('click') OnClick() {
    // if(this.tooltip)
    //   $('.ng-tooltip').removeClass('ng-tooltip-show');
  }
  @HostListener('window:scroll') OnScroll() {          
      this.setTooltipPos();    
  }  
  // create and show / hide tooltips
  showTooltip() {
    this.setTooltipPos();
    $('.ng-tooltip').html(this.toolTipTitle)
    $('.ng-tooltip').addClass('ng-tooltip-show');
     
  }
  hideTooltip() {
    $('.ng-tooltip').removeClass('ng-tooltip-show');
  }
  createTooltip() {
    this.tooltip = this.renderer.createElement('span');
  }

  // set tooltip position according to it's parent element
  setTooltipPos() {
    this.tooltip = this.renderer.createElement('span');
    const linkPos = this.elRef.nativeElement.getBoundingClientRect();
    const tooltipPos = this.tooltip.getBoundingClientRect();
    const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let top, left;

    if (this.placement === 'top') {
      top = linkPos.top - tooltipPos.height - this.offset;
      left = linkPos.left + (linkPos.width - tooltipPos.width) / 2;
    }
    if (this.placement === 'bottom') {
      top = linkPos.bottom + this.offset;
      left = linkPos.left + (linkPos.width - tooltipPos.width) / 2;
    }
    if (this.placement === 'left') {
      top = linkPos.top + (linkPos.height - tooltipPos.height) / 2;
      left = linkPos.left - tooltipPos.width - this.offset;
    }
    if (this.placement === 'right') {
      top = linkPos.top + (linkPos.height - tooltipPos.height) / 2;
      left = linkPos.right + this.offset;
    }
    $('.ng-tooltip').css({top: `${top + scrollPos}px`,'left': `${left}px`});
  }

}
