import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
	@Input() title: any = 'Confirmation';
	@Input() message: any = 'Are you sure to delete this ';
	@Input() moduleName: any = 'record';
	@Input() deviceName: any = '';
	@Input() status: any = '';
	@Input() dialogId: any = 'delete-dialog';
	@Input() cancelTitle: any = 'Cancel';
	@Input() successTitle: any = 'Delete';
	@Input() extraData: any;/*For extra data*/
	@Input() showCancelButton: any = true;
	@Output() onConfirm = new EventEmitter<string>();
	@Output() onCancel = new EventEmitter<string>();

	constructor() { }

	ngOnInit() { }

	clickOnCancel() {
		this.onCancel.emit();
	}

	clickOnSave() {
		this.onConfirm.emit();
	}
}
