export const environment = {
    production: true,
    hotjar: {
        enabled: true,
        id: 5028809
    },
    baseUrl: 'https://costagridentity.costa.express',
    apiUrl: {
        entity: 'https://costagridentity.costa.express',
        machine: 'https://costagridmachine.costa.express',
        user: 'https://costagriduser.costa.express',
        salesProcessor: 'https://costagridsales.costa.express',
        event: 'https://costagridnotification.costa.express',
        soxAuditLog: 'https://costagridaudit.costa.express',
        alarmProcessor: 'https://costagridalarm.costa.express',
        signalR: 'https://costagridsignalr.costa.express',
        remoteUpdate: 'https://remote-update-tasks-management-prd.azurewebsites.net',
        azureAppConfig: 'Endpoint=https://grid-ui.azconfig.io;Id=LKWA;Secret=oGTka6Bqlbf+lNKThvM7XcCULZF6d2Ievavu5tdvpd0=',
        remoteUpdatePackage: 'https://remote-update-packages-management-prd.azurewebsites.net'
    },
    cookieName: 'CE_',
    env: 'prod',
    version: '2.35.2',
    domainName: "costagrid.costa.express",
    siteKeyCaptcha: '6LfDn5ocAAAAAKkog37xYn2bqXtTEHn-r1v72sdy',
    msal: {
        authority: "https://login.microsoftonline.com/04ed63ba-9902-48da-b4cd-52b94b3a487e/",
        clientId: "15f2c4d8-61aa-44be-a6d3-6c3d59550b4b",
        redirectUrl: "https://costagrid.costa.express/callback",
        postLogoutRedirectUri: "https://costagrid.costa.express/",
        loginscope: "openid email profile",
        apiscope: "https://idam.costa.express/access_as_user",
        costaExpressUrl: "https://idam.costa.express"
    },
};
