import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { RoleService } from 'src/app/services/role/role.service';
import { SignalrService } from 'src/app/services/signalr/signalr.service';
import { TickerTapService } from 'src/app/services/ticker-tap/ticker-tap.service';
import { UserAuditService } from 'src/app/services/user-audit/user-audit.service';
import { UserService } from 'src/app/services/user/user.service';
import { UserAuditListComponent } from '../user-audit-list/user-audit-list/user-audit-list.component';
import { environment } from './../../../environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

 constructor(private userAuditService:UserAuditService, public dialog: MatDialog, private cookieService: CookieService,private spinner:NgxSpinnerService, private msalService: MsalService,public authService: AuthService,private router: Router,
  public roleService : RoleService,private notificationService: NotificationService,private toaster: Toaster,private userService:UserService, private signarService: SignalrService, public tickerTapMessageService: TickerTapService) { }
  cookieName = environment.cookieName;
  alerts:any=[];
  hasRefreshCalled = false;
  idealTimeInterval = parseInt(this.userService.defaultUIConfiuraitions.autoLogoutIdealTimeIntervalDurationInMinutes);
  costaAllTabs = '';
  lastUserActiveTime = '';
  @ViewChild("confirmDialogTemplate") confirmDialogTemplate: TemplateRef<any>;
  @ViewChild(UserAuditListComponent) UserAuditListComponent: UserAuditListComponent;
  dialogTexts = {
    successTitle: '',
    cancelTitle: '',
    message: '',
    deviceName: '',
    status: '',
    title: ''
  }
  dialogRef: MatDialogRef<any>;
  ngOnInit(): void { 
    //Live update message scheduler
    this.signarService.startConnection();
    this.signarService.messageActivateListener();
    this.signarService.messageExpiredListener();
   
    this.getCurrentActiveMessages();

    this.lastUserActiveTime = localStorage.getItem('LastUserActiveTime');
    this.authService.currentUserToken = this.cookieService.get(this.cookieName + 'access_token');
    let that = this;
    this.getLiveNotification(); 
    this.checkLastActionTime();
    var date = new Date();
    let minute = 1;
    let interval = (minute * 60) * 1000;

    setTimeout(() => {
      this.signarService.startConnection();
    }, 100000);

    setTimeout(function() {       
      setInterval(() => {
        that.checkAccessToken(); //to check access_token is near to expire or not every minute       
        let current_time = new Date();
        if ((current_time.getMinutes() % 5) == 0) {
            that.getLiveNotification();
        } 
        if ((current_time.getMinutes() % 1) == 0) {
          that.checkLastActionTime();
        }     
      },interval);
      let current_time = new Date();
      if ((current_time.getMinutes() % 5) == 0) {
        that.getLiveNotification();
      }  
        if ((current_time.getMinutes() % 1) == 0) {
          that.checkLastActionTime();
        }  
      that.checkAccessToken(); //to check access_token is near to expire or not every minute      
    }, (60 - date.getSeconds()) * 1000);

    this.tabInfoManage();       
  }
  tabInfoManage(){
    let tabData:any = [];
    if(localStorage.getItem('costaTabs'+environment.env)){
      tabData = localStorage.getItem('costaTabs'+environment.env);
      tabData = JSON.parse(tabData);           
    }
    this.authService.tabInfo = Date.now(); ;
    tabData.push(this.authService.tabInfo); 
    localStorage.setItem("costaTabs"+environment.env,JSON.stringify(tabData));

    setInterval(() => {
      let current_time = new Date();
      if (current_time.getSeconds() != 0 && (current_time.getSeconds() % 5) == 0) {           
        if(localStorage.getItem('costaTabs'+environment.env)){
          this.costaAllTabs =  localStorage.getItem('costaTabs'+environment.env);
          this.lastUserActiveTime = localStorage.getItem('LastUserActiveTime');
        }
      }
    },1000);
  }
  
	doBeforeUnload() {
    let tabData:any;
    if(localStorage.getItem('costaTabs'+environment.env)){
      tabData = JSON.parse(localStorage.getItem('costaTabs'+environment.env));      
      let index = tabData.indexOf(this.authService.tabInfo);
      if (index > -1) {
        tabData.splice(index, 1);
      }
      localStorage.setItem("costaTabs"+environment.env,JSON.stringify(tabData));    
    }		
	}
  getLiveNotification(){
    this.notificationService.getLiveNotifications().subscribe(response => {
      if (response != null && response.data && response.data.length) {
      this.alerts = response.data;
      }else{
        this.alerts = [];
      }
    }, err => {  
        this.alerts = [];    
        let errors = err.error;
        if(Array.isArray(errors)){
          errors.forEach(element => {
            this.toaster.open({
              text: element.msg,
              type: 'danger',
              position: 'top-right',
              duration: 10000
            });
          });
        }
    });
  }
  async refreshToken(){    
    const currentAccount = this.msalService.instance.getAllAccounts()[0];
    var silentRequest = {
        scopes: [environment.msal.loginscope],
        account: currentAccount,
        forceRefresh: false
    };
    
    const tokenResponse = await this.msalService.instance.acquireTokenSilent(silentRequest).then(accessTokenResponse => {
     if (accessTokenResponse != null && accessTokenResponse.accessToken != '') {
        this.cookieService.set(this.cookieName + 'b2baccess_token', accessTokenResponse.accessToken, 1, '/',environment.domainName, false, 'Lax');
			 
        this.authService.refreshToken(accessTokenResponse.idToken).then(res =>res.subscribe(response => {
        if(response){
          this.cookieService.set(this.cookieName + 'access_token', response.data.access_token, 1, '/',environment.domainName, false, 'Lax');
          this.authService.currentUserToken = this.cookieService.get(this.cookieName + 'access_token');
        }			     
      }, err => {  
        console.log('err',err);      
      }));
        
      }
    }, err => {
      console.log('err',err);
      
    });
  }
  checkAccessToken(){   
    
    let token = this.getDecodedAccessToken(this.cookieService.get(this.cookieName + 'access_token'));
    if(token && token['exp']){
      let expTime = new Date(token['exp'] * 1000);
      let current_time = new Date();
      var diff =(expTime.getTime() - current_time.getTime()) / 1000;
      diff /= (60); 
      diff = Math.abs(Math.round(diff));    
      if(!this.hasRefreshCalled && (expTime < current_time || diff < parseInt(this.userService.defaultUIConfiuraitions.rTokenDurationBeforeExpCTokenInMinutes))){ //if token is near to expire in X min then update
        this.hasRefreshCalled = true;
        this.refreshToken();
      }  
    } 
  }
  getDecodedAccessToken(token: string): any {
    try{
        return jwt_decode(token);
    }
    catch(Error){
        return null;
    }
  }
  Logout() {
    if((localStorage.getItem("isAuditInProgress") != undefined && this.authService.currentRoute == 'user-audit') || (localStorage.getItem("isTemplateEdit") != undefined && this.authService.currentRoute == 'alarm-template') || (localStorage.getItem("isUserEdit") != undefined && this.authService.currentRoute == 'user')){
      this.dialogTexts.message = 'All unsaved changes will be lost. Are you sure you want to exit?';
      if(localStorage.getItem("isUserEdit") != undefined && this.authService.currentRoute == 'user'){
        this.dialogTexts.message = 'All unsaved changes will be lost. Are you sure you want to cancel?';
      }
      this.getConfirmationBox();
      
    }else{
      this.router.navigate(['/logout']);
    }
  }
  checkLastActionTime(){   //this function is used to check ideal time interval
    if(environment.env != 'dev'){
      if(localStorage.getItem('LastUserActiveTime')){
        this.lastUserActiveTime = localStorage.getItem('LastUserActiveTime');
      }     
      if(!this.lastUserActiveTime){
        this.roleService.showToasterMsg('You have been logged out due to inactivity','danger');        
        this.authService.logOut();
      }else{
        let current_time = new Date();
        var diff =(current_time.getTime() - parseInt(this.lastUserActiveTime)) / 1000;
        diff /= (60); 
        diff = Math.abs(Math.round(diff)); 
        if(diff >= this.idealTimeInterval){
          
          let tabData:any;
          let callApi = true;
          if(this.costaAllTabs){
            tabData = JSON.parse(this.costaAllTabs);      
            let index = tabData.indexOf(this.authService.tabInfo);
            if (index != 0) {
              callApi = false;
            }          
          }
          this.roleService.showToasterMsg('You have been logged out due to inactivity','danger');        
          this.authService.logOut(callApi);
        }
      }
    }
  }
  getConfirmationBox() {
    this.dialogTexts = {
        successTitle: 'Yes',
        cancelTitle: 'No',
        message: this.dialogTexts.message,
        deviceName: '',
        status: '',
        title: 'Confirmation',        
    }    
    const eventPopupModel = new MatDialogConfig();
    eventPopupModel.height = 'auto';
    eventPopupModel.width = '670px';
    eventPopupModel.disableClose = true;
    this.dialogRef = this.dialog.open(this.confirmDialogTemplate, eventPopupModel);    
  }
  onCancel() {
    this.dialogRef.close();
  }
  onConfirm() {   
    this.spinner.show(); 
    this.dialogRef.close();
    if(localStorage.getItem("isAuditInProgress") != undefined && this.authService.currentRoute == 'user-audit'){
      this.userAuditService.stopaudit().subscribe(response => { 
        localStorage.removeItem("isAuditInProgress");
        this.authService.logOut();         
      },err =>{
        this.spinner.hide();
        this.authService.logOut();
        this.roleService.showErrorMsg(err);
      }); 
    }else{
      localStorage.removeItem("isTemplateEdit");
      localStorage.removeItem("isUserEdit");
      this.authService.logOut();
    }
  }

  getCurrentActiveMessages() {
    this.spinner.show();
    this.tickerTapMessageService.getCurrentActiveTickerTapMessages().subscribe(result => {
      this.spinner.hide();
      if (result && result['data'])
        this.tickerTapMessageService.scheduleMessages = result['data'];
        for(let index = 0; index < this.tickerTapMessageService.scheduleMessages.length; index++){
          this.tickerTapMessageService.scheduleMessages[index].message = "<p class='marqee-mark-p'>" + this.tickerTapMessageService.scheduleMessages[index].htmlMessage + "</p>";
        }
    }, err => {
      this.spinner.hide();
      let errors = err.errors == undefined ? err.error : err.errors;
      if (Array.isArray(errors)) {
        errors.forEach(element => {
          this.toaster.open({
            text: element.msg,
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
        });
      }
    });
  }
}
