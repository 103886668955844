import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  cookieName = environment.cookieName;
	constructor(private cookieService: CookieService,
		private httpClient: HttpClient) { }

  getNotifications(body) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
    const parameter = {
			params: body,
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.apiUrl.event + '/api/v1/notifications',reqParameter).pipe(map(response => {
			return response;
		}));
	}
	getLiveNotifications() {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		const parameter = {
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.apiUrl.event + '/api/v1/livenotifications',reqParameter).pipe(map(response => {
			return response;
		}));
	}	
  
  getLastNotificationDate() {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.get<any>(environment.apiUrl.event + '/api/v1/notifications/lastupdatedate', configHeader).pipe(map(response => {
			return response;
		}));
	}

  moveToCompletedNotification(notificationId) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.put<any>(environment.apiUrl.event + '/api/v1/notifications/notificationId?notificationId='+notificationId,null, configHeader).pipe(map(response => {
			return response;
		}));
	}

	getMachineLookup(parentEntityId) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		var url = '/api/v1/machine';
		if(parentEntityId != null){
			url = url +'?parentEntityId='+parentEntityId;
		}
		return this.httpClient.get<any>(environment.apiUrl.event + url, configHeader).pipe(map(response => {
			return response;
		}));
	}
	
	getIssueTypeLookup() {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.get<any>(environment.apiUrl.event + '/api/v1/notificationtype', configHeader).pipe(map(response => {
			return response;
		}));
	}

	getCompletedByLookup() {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.get<any>(environment.apiUrl.event + '/api/v1/completedby', configHeader).pipe(map(response => {
			return response;
		}));
	}

	getDataIssueLogs(body) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
    const parameter = {
			params: body,
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.apiUrl.event + '/api/v1/notifications/dataissuelogs',reqParameter).pipe(map(response => {
			return response;
		}));
	}
}
