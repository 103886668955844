import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from "@angular/router";
import { AlarmTemplateAddComponent } from "src/app/components/alarm-template/alarm-template-add/alarm-template-add.component";

@Injectable()
export default class AlarmTemplateAddCanDeactivateGuard
    implements CanDeactivate<AlarmTemplateAddComponent> {
    canDeactivate(component,_route: ActivatedRouteSnapshot, _state:           
        RouterStateSnapshot,nextState?: RouterStateSnapshot) {  
            let  selectedModel = localStorage.getItem("isTemplateEdit");
            if(selectedModel != undefined)
                return component.canDeactivate(nextState.url);
            else
                return true;            
    }
}


