export class AlertConfirmDialogModel {
    title: string;
    okButtonName: string = 'Ok';
    cancelButtonName: string = 'Cancel';
    requestBody:any;
    result:boolean = false;
    dialogId:string;
    action:string;
    message:string;
    isVisibleCancel:boolean = true;
}