import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserAuditService {
  cookieName = environment.cookieName;
	constructor(
    private cookieService: CookieService,
    private httpClient: HttpClient
    ) { }
	
	getAuditList(): Observable<any> {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
			}
		};
		return this.httpClient.get<any>(environment.apiUrl.user + '/api/v1/useraudit/getusersforaudit', configHeader).pipe(map(response => {
			return response;
		}));
	}
	getStartaudit(): Observable<any> {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
			}
		};
		return this.httpClient.get<any>(environment.apiUrl.user + '/api/v1/useraudit/checkStartAudit', configHeader).pipe(map(response => {
			return response;
		}));
	}
	stopaudit(): Observable<any> {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
			}
		};
		return this.httpClient.put<any>(environment.apiUrl.user + '/api/v1/useraudit/stopaudit',{}, configHeader).pipe(map(response => {
			return response;
		}));
	}
	startaudit(): Observable<any> {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
			}
		};
		return this.httpClient.put<any>(environment.apiUrl.user + '/api/v1/useraudit/startaudit',{}, configHeader).pipe(map(response => {
			return response;
		}));
	}
	submitAudit(body): Observable<any> {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
			}
		};
		return this.httpClient.post<any>(environment.apiUrl.user + '/api/v1/useraudit/submitAudit',body, configHeader).pipe(map(response => {
			return response;
		}));
	}
}
