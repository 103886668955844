import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { environment } from './../../../environments/environment';
export const FAILURE_LOG_TYPES = {
    entity: 1,
    machine: 2,
    salesProcessor: 3,
    user: 4
}
export const METHOD_TYPES = {
    POST: 1,
    PUT: 2,
    DELETE: 3,
    GET: 5
}
export const PriceCardAction = {
    FinalizePriceCard : 1,
    PriceCardStatus : 2,
    PriceCardDetails : 3,
    DeletePriceCard : 4
}

@Injectable({
    providedIn: 'root'
})
export class SOXAuditLogsService {
    cookieName = environment.cookieName;
    constructor(private cookieService: CookieService,
        private httpClient: HttpClient, private authSevice: AuthService) { }

    getSOXAuditLogsList(body) {
        var configHeader = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
            }
        };
        return this.httpClient.post<any>(environment.apiUrl.soxAuditLog + '/api/v1/auditLog/getAuditLogList', body, configHeader).pipe(map(response => {
            return response;
        }));

    }

    exportSOXAuditLogsPdf(body) {
        var configHeader = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
            }
        };
        return this.httpClient.post<any>(environment.apiUrl.soxAuditLog + '/api/v1/auditLog/downloadAuditLogPdf', body, configHeader).pipe(map(response => {
            return response;
        }));

    }

    getEntityLookup(levelNumber) {
        var configHeader = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
                'b2baccess_token': this.cookieService.get(this.cookieName + 'b2baccess_token')
            }
        };
        return this.httpClient.get<any>(environment.apiUrl.soxAuditLog + '/api/v1/entity/' + levelNumber, configHeader).pipe(map(response => {
            return response;
        }));

    }

    getUserLookup(body) {
        var configHeader = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
            }
        };
        return this.httpClient.post<any>(environment.apiUrl.soxAuditLog + '/api/v1/user/getUserByEntity', body, configHeader).pipe(map(response => {
            return response;
        }));
    }
    
    isUUID(s=''){
        return s.match("^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$");
    }

    addFailureLog(body, moduleType = 0) {
        var configHeader = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        let urlType = 'addSystemFailureLog';
        if (this.authSevice.isCheckLogin()) {
            configHeader['headers']['Authorization'] = 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token');
            if (moduleType == FAILURE_LOG_TYPES.user) {
                urlType = 'addUserFailureLog';
            } else if (moduleType == FAILURE_LOG_TYPES.entity) {
                if(body['route'] && (body['route'] == 'pc-templage')){
                    urlType = 'addPriceCardTemplateFailureLog';
                }else if(body['route'] && (body['route'] == 'price-card')){                    
                    urlType = 'addPriceCardFailureLog';                    
                }else{
                    urlType = 'addEntityFailureLog';
                }
            } else if (moduleType == FAILURE_LOG_TYPES.machine) {
                urlType = 'addEntityFailureLog';
            } else if (moduleType == FAILURE_LOG_TYPES.salesProcessor) {
                if(body['discrepancyType']== "Configuration")
                    urlType = 'addSalesConfigurationDiscrepancyFailureLog';
                else
                    urlType = 'addSalesDiscrepancyFailureLog';
            } else {
                urlType = 'addFailureLog';
            }
        }
        return this.httpClient.post<any>(environment.apiUrl.soxAuditLog + '/api/v1/auditLog/' + urlType, body, configHeader).pipe(map(response => {
            return response;
        }));
    }
    getlogTypeByUrl(url) {
        if (url.includes(environment.apiUrl.entity)) {
            return FAILURE_LOG_TYPES.entity;
        } else if (url.includes(environment.apiUrl.machine)) {
            return FAILURE_LOG_TYPES.machine;
        } else if (url.includes(environment.apiUrl.user)) {
            return FAILURE_LOG_TYPES.user;
        } else if (url.includes(environment.apiUrl.salesProcessor)) {
            return FAILURE_LOG_TYPES.salesProcessor;
        } else if (url.includes(environment.apiUrl.soxAuditLog)) {
            return 0;
        } else {
            return 0;
        }
    }
    addIngridentFailureLog(body,moduleType=0) {
        var configHeader = {
            headers: {
                'Content-Type': 'application/json',
            }            
        };
        if(this.authSevice.isCheckLogin()){
            configHeader['headers']['Authorization'] = 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token');
        }
        let urlType = 'addIngridentConfigurationFailureLog';
        return this.httpClient.post<any>(environment.apiUrl.soxAuditLog + '/api/v1/auditLog/'+urlType, body, configHeader).pipe(map(response => {
            return response;
        }));
    }
    getRouteTypeByUrl(url) {
        if (url.includes(environment.apiUrl.entity)) {
            return 'Entity';
        } else if (url.includes(environment.apiUrl.machine)) {
            return 'Machine';
        } else if (url.includes(environment.apiUrl.user)) {
            return 'User';
        } else if (url.includes(environment.apiUrl.salesProcessor)) {
            return 'SalesProcessor';
        } else if (url.includes(environment.apiUrl.soxAuditLog)) {
            return 'SoxAuditLog';
        } else if (url.includes(environment.apiUrl.event)) {
            return 'Event';
        } else {
            return 'Entity';
        }
    }
    exportsSOXAuditLogExcel(body) {
        var configHeader = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
            }
        };
        return this.httpClient.post<any>(environment.apiUrl.soxAuditLog + '/api/v1/auditLog/downloadAuditLogExcel', body, configHeader).pipe(map(response => {
            return response;
        }));
    }
}
