import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AlertConfirmDialogModel } from './alert-confirm.dialog.model';

@Component({
	selector: 'app-alert-confirm-dialog',
	templateUrl: './alert-confirm-dialog.component.html',
	styleUrls: ['./alert-confirm-dialog.component.css']
})
export class AlertConfirmDialogComponent implements OnInit {
	@Input() alertConfirmDataModel: AlertConfirmDialogModel;
  	@Output() onConfirm = new EventEmitter<any>();
  	@Output() onCancel = new EventEmitter<string>();

	constructor() { }

	ngOnInit() { }

	clickOnSave() {
		this.onConfirm.emit();
	}
	clickOnCancel() {
		this.onCancel.emit();
	}
}
