import { Injectable } from '@angular/core';
import { AppConfigurationClient } from "@azure/app-configuration";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AzureService {
  private client: AppConfigurationClient;

  constructor() {
    this.client = new AppConfigurationClient(environment.apiUrl.azureAppConfig);
  }

  public async isAddEntityV2Enabled(): Promise<boolean> {
    return await this.executeRequest('UseAddEntityV2FT');
  }

  public async isUseAddMachineV2FTEnabled(): Promise<boolean> {
    return await this.executeRequest('UseAddMachineV2FT');
  }

  public async isUseUserAuthV2FT(): Promise<boolean> {
    return await this.executeRequest('UseUserAuthV2FT');
  }

  public async isRemoteUpdateFT(): Promise<boolean> {
    return await this.executeRequest('RemoteUpdateFT');
  }

  public async isAutonomousMachinesFT(): Promise<boolean> {
    return await this.executeRequest('AutonomousMachinesFT');
  }

  public async isGlobalChangesFT(): Promise<boolean> {
    return await this.executeRequest('GlobalChangesFT');
  }

  public async isEnableCreateConcessionFT(): Promise<boolean> {
    return await this.executeRequest('EnableCreateConcessionFT');
  }

  public async isPriceCardByPropositionFT(): Promise<boolean> {
    return await this.executeRequest('PriceCardByPropositionFT');
  }

  public async isEnableDrinkSetupFT(): Promise<boolean> {
    return await this.executeRequest('EnableDrinkSetupFT');
  }

  public async isLowAdminFT(): Promise<boolean> {
    return await this.executeRequest('EnableLowAdmin');
  }

  public async isEnableEnableDisableDrinksTask(): Promise<boolean> {
    return await this.executeRequest('EnableDisableDrinksTaskFT');
  }

  public async isCertificateManagementTask(): Promise<boolean> {
    return await this.executeRequest('CertificateManagementTaskFT');
  }

  private async executeRequest(flagName: string): Promise<boolean> {
    const flag = await this.client.getConfigurationSetting({
      key: ".appconfig.featureflag/" + flagName
    });

    return !!flag.value ? JSON.parse(flag.value).enabled : false;;

  }

  public async isDisableConcessionLockdownPropertiesFT(): Promise<boolean> {
    try{
      return await this.executeRequest('DisableConcessionLockdownPropertiesFT');
    }
    catch{}
    return false;
  }

  public async isEnableMachineDataFT(): Promise<boolean> {
    return await this.executeRequest('EnableMachineDataFT');
  }

  public async isEnableGridImprovementsFT(): Promise<boolean> {
    return await this.executeRequest('EnableGridImprovementsFT');
  }
}
