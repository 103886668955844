import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AzureService } from '../azure/azure.service';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	cookieName = environment.cookieName;
	defaultUIConfiuraitions = {
		eventRequiredLanguageName: 'English',
		alarmFilterEscalationRangeMax: "129600",
		alarmFilterEscalationRangeMin: "0",
		alarmFilterOccurrenceDateMinInDays: "90",
		autoLogoutIdealTimeIntervalDurationInMinutes: "15",
		entityLevel3_5LableName: "3.5",
		eventChineseSMSMaxLength: "59",
		eventDataFilterDateMinInMonths: "6",
		eventEnglishSMSMaxLength: "149",
		machineSalesFilterDateMinInMonths: "6",
		notificationFilterCompletedFromInDays: "29",
		notificationFilterLogsDateMaxRangeInDays: "89",
		notificationFilterLogsDateMinInYears: "7",
		notificationFiltercompletedDateMinRangeInDays: "30",
		occurrenceDateRangeMinInDays: "90",
		rTokenDurationBeforeExpCTokenInMinutes: "5",
		salesListFilterDateMinInMonths: "6",
		soxListFilterDateMaxRangeInMonths: "3",
		soxListFilterDateMinInMonths: "18",
		userVerificationListFilterDateMinInMonths: "18",
		soxListFilterDefaultDateRangeInDay: "1"
	};
	userDetails = {};
	constructor(
		private cookieService: CookieService,
		private httpClient: HttpClient,
		private azureService: AzureService
	) { }

	getEntityLevelLookup() {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
				'b2baccess_token': this.cookieService.get(this.cookieName + 'b2baccess_token')
			}
		};
		return this.httpClient.get<any>(environment.apiUrl.user + '/api/v1/entitylevel', configHeader).pipe(map(response => {
			return response;
		}));

	}

	getEntityLookup(levelNumber) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
				'b2baccess_token': this.cookieService.get(this.cookieName + 'b2baccess_token')
			}
		};
		return this.httpClient.get<any>(environment.apiUrl.user + '/api/v1/entity?LevelNumber=' + levelNumber, configHeader).pipe(map(response => {
			return response;
		}));

	}

	getEntityByRoleIdLookup(RoleId) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
				'b2baccess_token': this.cookieService.get(this.cookieName + 'b2baccess_token')
			}
		};
		return this.httpClient.get<any>(environment.apiUrl.user + '/api/v1/entity/getbyroleid/' + RoleId, configHeader).pipe(map(response => {
			return response;
		}));

	}

	getRoleTypeLookup() {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.get<any>(environment.apiUrl.user + '/api/v1/roleType', configHeader).pipe(map(response => {
			return response;
		}));
	}

	getRoleLookup(isFromAdd: any = false) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
				'b2baccess_token': this.cookieService.get(this.cookieName + 'b2baccess_token')
			}
		};
		const parameter = {
			params: { isAddScreen: isFromAdd },
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.apiUrl.user + '/api/v1/role', reqParameter).pipe(map(response => {
			return response;
		}));

	}

	async deleteUser(id) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
				'b2baccess_token': this.cookieService.get(this.cookieName + 'b2baccess_token')

			}
		};

		let baseUrl = '/api/v1/user/';

		let shouldUseUserAuthV2 = await this.azureService.isUseUserAuthV2FT();

		if (shouldUseUserAuthV2)
			baseUrl = '/api/v2/user/';

		return this.httpClient.delete<any>(environment.apiUrl.user + baseUrl + id, configHeader).pipe(map(response => {
			return response;
		}));
	}

	async saveUser(body, accessToken) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
				'b2baccess_token': this.cookieService.get(this.cookieName + 'b2bAddUseraccess_token'),
				'b2bapiaccess_token': accessToken
			}
		};

		let baseUrl = '/api/v1/user/';

		let shouldUseUserAuthV2 = await this.azureService.isUseUserAuthV2FT();

		if (shouldUseUserAuthV2)
			baseUrl = '/api/v2/user/';

		return this.httpClient.post<any>(environment.apiUrl.user + baseUrl, body, configHeader).pipe(map(response => {
			return response;
		}));
	}

	async updateUser(body, userId, accessToken) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
				'b2baccess_token': this.cookieService.get(this.cookieName + 'b2bAddUseraccess_token'),
				'b2bapiaccess_token': accessToken
			}
		};

		let baseUrl = '/api/v1/user/';

		let shouldUseUserAuthV2 = await this.azureService.isUseUserAuthV2FT();

		if (shouldUseUserAuthV2)
			baseUrl = '/api/v2/user/';

		return this.httpClient.put<any>(environment.apiUrl.user + baseUrl + userId, body, configHeader).pipe(map(response => {
			return response;
		}));
	}

	getUserById(userId): Observable<any> {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
				'b2baccess_token': this.cookieService.get(this.cookieName + 'b2baccess_token')
			}
		};
		const parameter = {
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.apiUrl.user + '/api/v1/user/' + userId, reqParameter)
	}

	getUserList(body): Observable<any> {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
				'b2baccess_token': this.cookieService.get(this.cookieName + 'b2baccess_token')
			}
		};

		const parameter = {
			params: body,
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.apiUrl.user + '/api/v1/user', reqParameter).pipe(map(response => {
			return response;
		}));
	}

	getUserVerificationList(body): Observable<any> {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
				//'b2baccess_token': this.cookieService.get(this.cookieName + 'b2baccess_token')
			}
		};
		const parameter = {
			params: body,
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.apiUrl.user + '/api/v1/userreview', reqParameter).pipe(map(response => {
			return response;
		}));
	}
	exportUserVerificationData(data) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.post<any>
			(environment.apiUrl.user + '/api/v1/userreviewexport', data, configHeader)
			.pipe(map(response => {
				return response;
			}))
	}
	userreviewexportpdf(data) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.post<any>
			(environment.apiUrl.user + '/api/v1/userreviewexportpdf', data, configHeader)
			.pipe(map(response => {
				return response;
			}))
	}
	getUserAccess(userId) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		this.getUIConfigurations().subscribe(async (response) => { //to get default UI configurations
		});
		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/useraccess/' + userId, configHeader).pipe(map(response => {
      this.cookieService.delete(this.cookieName + 'userId', '/', environment.domainName);
			this.cookieService.set(this.cookieName + 'userId', response.data.userDetail.id, 1, '/', environment.domainName, false, 'Lax');
			response.data.userDetail['marketId'] = response.data.marketId;
			response.data.userDetail['parentId'] = response.data.parentId;
			this.userDetails = response.data.userDetail;
			return response;
		}));

	}
	getUIConfigurations() {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/getconfigurations', configHeader).pipe(map(response => {
			if (response && response.result) {
				if (typeof response.result === 'object' && response.result !== null) {
					for (var key in response.result) {
						this.defaultUIConfiuraitions[key] = response.result[key];
					}
				}
			}
			return response;
		}));
	}
	getUserEmailUpdateHistory(userId) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
			}
		};
		const parameter = {
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.apiUrl.user + '/api/v1/useremailupdatehistory/' + userId, reqParameter);
	}
}
