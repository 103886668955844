import { createReducer, on } from '@ngrx/store';
import { setMachineListFilter } from './machine-list-filter.actions';
import { MachineListFilter } from './machine-list-filter.model';

const initialFilterState = {
  propositionTypeId: [],
  marketId: '',
  partnerIds: [],
  siteIds: [],
  machineType: '',
  machineStatus: '',
  connectivityStatus: '',
  workingStatus: '',
  commissionningStatus: '',
  search: '',
  targetWorkingState: '',
  suspendReason: ''
};

export const initialState: MachineListFilter = {
  filter: { ...initialFilterState },
  tempFilter: { ...initialFilterState },
  advancedFilter: {
    entityID: false,
    entityName: false,
    billTo: false,
    sellTo: false,
    reference: false,
    machineSerialNumber: false
  },
  sortBy: ''
};

const _machineListFilterReducer = createReducer(
  initialState,
  on(setMachineListFilter, (state, { filter }) => ({ ...state, ...filter }))
);

export function machineListFilterReducer(state: any, action: any) {
  return _machineListFilterReducer(state, action);
}