import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RoleService } from 'src/app/services/role/role.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingComponent implements OnInit {

  loaded = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private roleService: RoleService,
    private spinnerService: NgxSpinnerService,
  ) {
  }

  ngOnInit(): void {
    this.spinnerService.show();
    if (this.authService.isCheckLogin()) {
      const auditRoles = ['isUserAuditorGRID', 'isUserAuditorLevel1', 'isUserAuditorLevel2', 'isUserAuditorLevel3'];
      this.spinnerService.hide();
      if (this.roleService.hasUserAccess(auditRoles)) {
        this.router.navigate(['/user-audit']);
      } else {
        this.router.navigate(['/machine']);
      }
    } else {
      this.loaded = true;
      this.spinnerService.hide();
    }
  }
}
