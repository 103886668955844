import { RoleModuleMasterConstant, RolePermissionsEnum, RoleScreenMasterConstant } from './services/role/role.service';
import { RouterModule, Routes } from '@angular/router';
import { AuthService } from './services/auth/auth.service';
import { LandingComponent } from './components/landing/landing/landing.component';
import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import UserAuditCanDeactivateGuard from './services/user-audit/user-audit-deactivate.guard';
import AlarmTemplateAddCanDeactivateGuard from './services/alarm-template/alarm-deactivate.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule),
    canActivate: [AuthService],
  },
  {
    path: 'logout',
    loadChildren: () => import('./components/logout/logout.module').then(m => m.LogoutModule),
    canActivate: [],
  },
  {
    path: 'callback',
    loadChildren: () => import('./components/callback/callback.module').then(m => m.CallbackModule),
    canActivate: [],
  },
  {
    path: 'layout',
    loadChildren: () => import('./components/sitelayout/layout.module').then(m => m.LayoutModule),
  },
  {
    path: 'unauthorized',
    loadChildren: () => import('./components/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./components/error/error.module').then(m => m.ErrorModule),
  },
  { path: '', component: LandingComponent },
  { path: 'dashboard', redirectTo: '/machine', pathMatch: 'full' },
  {
    path: 'entity',
    loadChildren: () => import('./components/entity/entity-list/entity.module').then(m => m.EntityModule),
    canActivate: [AuthService],
  },
  {
    path: 'machine',
    loadChildren: () => import('./components/machines/machine.module').then(m => m.MachineModule),
    canActivate: [AuthService],
  },
  {
    path: 'price-card-template',
    loadChildren: () => import('./components/pricecard-template/pricecard-template-add/price-card-template.module')
      .then(m => m.PriceCardTemplateModule),
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[3].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[10].RoleScreenId, RoleScreenMasterConstant[11].RoleScreenId],
      permissionIds: [RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'drinks-setup',
    loadChildren: () => import('./components/drinks-setup/drink-setup.module').then(m => m.DrinkSetupModule),
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[3].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[10].RoleScreenId, RoleScreenMasterConstant[11].RoleScreenId],
      permissionIds: [RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'price-card',
    loadChildren: () => import('./components/price-card/price-card.module').then(m => m.PriceCardModule),
    canActivate: [AuthService],
  },
  {
    path: 'configuration',
    loadChildren: () => import('./components/master-configuration/configuration.module').then(m => m.ConfigurationModule),
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[3].RoleModuleId, RoleModuleMasterConstant[17].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[9].RoleScreenId, RoleScreenMasterConstant[64].RoleScreenId],
      permissionIds: [RolePermissionsEnum.ReadWrite, RolePermissionsEnum.Read],
    },
  },
  {
    path: 'sales',
    loadChildren: () => import('./components/sales/sales.module').then(m => m.SalesModule),
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[7].RoleModuleId],
      screenIds: [
        RoleScreenMasterConstant[30].RoleScreenId,
        RoleScreenMasterConstant[31].RoleScreenId,
        RoleScreenMasterConstant[32].RoleScreenId,
        RoleScreenMasterConstant[33].RoleScreenId,
        RoleScreenMasterConstant[34].RoleScreenId,
        RoleScreenMasterConstant[35].RoleScreenId,
        RoleScreenMasterConstant[36].RoleScreenId,
        RoleScreenMasterConstant[37].RoleScreenId,
        RoleScreenMasterConstant[38].RoleScreenId,
      ],
      permissionIds: [RolePermissionsEnum.Read],
    },
  },
  {
    path: 'user',
    loadChildren: () => import('./components/user/user.module').then(m => m.UserModule),
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[5].RoleModuleId],
      screenIds: [
        RoleScreenMasterConstant[23].RoleScreenId,
        RoleScreenMasterConstant[24].RoleScreenId,
        RoleScreenMasterConstant[25].RoleScreenId,
        RoleScreenMasterConstant[26].RoleScreenId,
        RoleScreenMasterConstant[27].RoleScreenId,
        RoleScreenMasterConstant[28].RoleScreenId,
        RoleScreenMasterConstant[29].RoleScreenId,
        RoleScreenMasterConstant[52].RoleScreenId,
        RoleScreenMasterConstant[53].RoleScreenId,
      ],
      permissionIds: [RolePermissionsEnum.Read],
    },
  },
  {
    path: 'notifications',
    loadChildren: () => import('./components/notification/notifications.module').then(m => m.NotificationsModule),
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[8].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[40].RoleScreenId, RoleScreenMasterConstant[43].RoleScreenId],
      permissionIds: [RolePermissionsEnum.Read, RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'audit-logs',
    loadChildren: () => import('./components/sox-audit-logs/audit-logs.module').then(m => m.AuditLogsModule),
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[9].RoleModuleId],
      screenIds: [
        RoleScreenMasterConstant[44].RoleScreenId,
        RoleScreenMasterConstant[45].RoleScreenId,
        RoleScreenMasterConstant[56].RoleScreenId,
        RoleScreenMasterConstant[57].RoleScreenId,
        RoleScreenMasterConstant[60].RoleScreenId,
        RoleScreenMasterConstant[61].RoleScreenId,
        RoleScreenMasterConstant[63].RoleScreenId,
      ],
      permissionIds: [RolePermissionsEnum.Read, RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'user-verification-review',
    loadChildren: () => import('./components/user-verification-review/user-verification-review.module')
      .then(m => m.UserVerificationReviewModule),
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[10].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[46].RoleScreenId],
      permissionIds: [RolePermissionsEnum.Read, RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'user-audit',
    loadChildren: () => import('./components/user-audit-list/user-audit.module').then(m => m.UserAuditModule),
    canDeactivate: [UserAuditCanDeactivateGuard],
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[16].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[62].RoleScreenId],
      permissionIds: [RolePermissionsEnum.Read, RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'alarm-template',
    loadChildren: () => import('./components/alarm-template/alarm-template.module').then(m => m.AlarmTemplateModule),
    canActivate: [AuthService],
    canDeactivate: [AlarmTemplateAddCanDeactivateGuard],
    data: {
      moduleIds: [RoleModuleMasterConstant[13].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[49].RoleScreenId],
      permissionIds: [RolePermissionsEnum.Read, RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'alarms',
    loadChildren: () => import('./components/alarm-template/alarm-list/alarms.module').then(m => m.AlarmsModule),
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[14].RoleModuleId, RoleModuleMasterConstant[15].RoleModuleId],
      screenIds: [
        RoleScreenMasterConstant[50].RoleScreenId,
        RoleScreenMasterConstant[58].RoleScreenId,
        RoleScreenMasterConstant[59].RoleScreenId,
      ],
      permissionIds: [RolePermissionsEnum.Read, RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'task-management-overview',
    loadChildren: () => import('./components/remote-update-tasks/task-management-overview.module')
      .then(m => m.TaskManagementOverviewModule),
    canActivate: [AuthService],
  },
  { path: '**', component: PageNotFoundComponent, canActivate: [AuthService] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
