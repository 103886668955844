import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { NgScrollbar } from 'ngx-scrollbar';
import { Toaster } from 'ngx-toast-notifications';
import { AuthService } from 'src/app/services/auth/auth.service';
import { EntityService } from 'src/app/services/entity/entity.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { NotificationCountService } from 'src/app/services/notification/notificationCountService.service';
import { RoleService } from 'src/app/services/role/role.service';
import { environment } from 'src/environments/environment';
import { AlertConfirmDialogModel } from '../common/alert-confirm-dialog/alert-confirm.dialog.model';
import { AzureService } from 'src/app/services/azure/azure.service';

declare var $: any;

@Component({
  selector: 'app-leftnavmenu',
  templateUrl: './leftnavmenu.component.html',
  styleUrls: ['./leftnavmenu.component.css']
})

export class LeftnavmenuComponent implements OnInit {
  @ViewChild(NgScrollbar) scrollRef: NgScrollbar;
  currentUrl: any;
  notificationCount: number = 0;
  remoteUpdateFT: boolean;
  cookieName = environment.cookieName;
  drinkSetupFT: boolean = false;
  menus = [
    {
      MenuName: "Entity",
      ModuleId: [2, 3],
      isdisplay: false
    },
    {
      MenuName: "Market",
      ModuleId: [2, 3],
      isdisplay: false
    },
    {
      MenuName: "Price Card",
      ModuleId: [4],
      isdisplay: false
    },
    {
      MenuName: "Sales",
      ModuleId: [8],
      isdisplay: false
    },
    {
      MenuName: "Notifications",
      ModuleId: [9],
      isdisplay: false
    },
    {
      MenuName: "User Verification Review",
      ModuleId: [11],
      isdisplay: false
    },
    {
      MenuName: "Alarm Template",
      ModuleId: [14],
      isdisplay: false
    },
    {
      MenuName: "Alarms",
      ModuleId: [15],
      isdisplay: false
    },
    {
      MenuName: "View Audit Logs",
      ModuleId: [10],
      isdisplay: false
    },
    {
      MenuName: "User Audit",
      ModuleId: [17],
      isdisplay: false
    },
    {
      MenuName: "MachineType",
      ModuleId: [18],
      isdisplay: false
    }
  ]
  @ViewChild("infoAlertTemplate") infoAlertTemplate: TemplateRef<any>;
  alertConfirmDataModel: AlertConfirmDialogModel = new AlertConfirmDialogModel();
  dialogRef: MatDialogRef<any>;
  previousFocusOutObj = '';
  constructor(public authService: AuthService
              ,public roleService: RoleService
              ,private toaster: Toaster
              ,private notificationService: NotificationService
              ,private notificationCountService: NotificationCountService
              ,public dialog: MatDialog
              ,private cookieService: CookieService
              ,private entityService: EntityService
              ,private azureService: AzureService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.SetFeatureFlagsConfigurations();

    if(this.roleService.userPermissions == undefined || this.roleService.userPermissions == null || this.roleService.userPermissions.length == 0) {
      this.roleService.getUserAccess().then(async (response) => {
        if (response) {
          for (var i = 0; i < this.menus.length; i++) {
            this.isDisplayMenu(this.menus[i].ModuleId, i).then((response) => {
            });
          }
        }
      });
    } else {
      for (var i = 0; i < this.menus.length; i++) {
        this.isDisplayMenu(this.menus[i].ModuleId, i).then((response) => {
        });
      }
    }

    this.notificationCountService.telecast.subscribe((result: any) => {
      this.notificationCount = result;
    });
    if (this.roleService.objRole.isSystemAdmin || this.roleService.objRole.isGlobalMarketAdmin || this.roleService.objRole.isMarketAdmin || this.roleService.objRole.isLevel2Admin || this.roleService.objRole.isLevel3Admin || this.roleService.objRole.isUserAuditorLevel1R || this.roleService.objRole.isUserAuditorLevel2R || this.roleService.objRole.isUserAuditorLevel3R){
      this.getNotificationsCount();
    }
    if (!this.roleService.objRole.isSystemAdmin && !this.roleService.objRole.isGlobalMarketAdmin && !this.roleService.objRole.isGridR && !this.roleService.objRole.isUserAuditorGRID && !this.roleService.objRole.isUserAuditorGRIDR) {
      this.getAdvanceSearchFilterOptions();
    } else {
      this.entityService.marketHasBillToField = true;
      this.entityService.marketHasSellToField = true;
    }
  }

  ngAfterViewInit() {
    $('.ng-tooltip').removeClass('ng-tooltip-show');
    $(document).mousemove(function(event) {
      if(event.pageX > 140){
        $('.ng-tooltip').html('')
      }
    });

    $(document).focusout(function(event) {
      if(this.previousFocusOutObj != event.target){
        this.previousFocusOutObj = event.target;
        localStorage.setItem('LastUserActiveTime',new Date().getTime().toString());
      }
    });
  }

  isDisplayMenu(moduleIds, i): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.roleService.checkMenuPermissions(moduleIds).then((response) => {
        if (response) {
          this.menus[i].isdisplay = true;
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  getNotificationsCount() {
    var body = {
      'CheckHasOutStandingNotifications': true,
      'IsOutStanding': false,
      'IsCompleted': false,
      'PageNo': 1
    }
    this.notificationService.getNotifications(body).subscribe(response => {
      if (response != null) {
        this.notificationCount = response?.params?.count;
        this.notificationCountService.updateCount(this.notificationCount);

        if (response?.params?.count > 0) {
          if (this.cookieService.get(this.cookieName + 'fromLandingPage') != null && this.cookieService.get(this.cookieName + 'fromLandingPage') == '1')
          {
            this.cookieService.delete(this.cookieName + 'fromLandingPage', '/', environment.domainName);
            const alertConfirmDataModel = new MatDialogConfig();
            alertConfirmDataModel.height = 'auto';
            alertConfirmDataModel.width = '670px';
            alertConfirmDataModel.disableClose = true;
            this.alertConfirmDataModel = new AlertConfirmDialogModel();
            this.alertConfirmDataModel.action = "Information";
            this.alertConfirmDataModel.dialogId = "outstanding-notification-alert";
            this.alertConfirmDataModel.okButtonName = "Close";
            this.alertConfirmDataModel.isVisibleCancel = false;
            this.alertConfirmDataModel.title = "Alert";
            this.alertConfirmDataModel.message = "<p class='text-left'>You have outstanding issues, please review your system notification management screen</p>";
            this.dialogRef = this.dialog.open(this.infoAlertTemplate, alertConfirmDataModel);
          }
        }
      }
    }, err => {
    let errors = err.error;
      if(Array.isArray(errors)){
        errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
        });
      }
    });
  }

  onConfirmOk() {
    this.dialogRef.close();
  }

  getAdvanceSearchFilterOptions() {
    this.entityService.getAdvanceSearchFilters().subscribe(response => {
      if (response != null && response['data']) {
        this.entityService.marketHasBillToField = response['data'].isBillToEnabled;
        this.entityService.marketHasSellToField = response['data'].isSellToEnabled;
      }
    }, err => {
    let errors = err.error;
      if(Array.isArray(errors)){
        errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
        });
      }
    });
  }

  private async SetFeatureFlagsConfigurations() {
    this.remoteUpdateFT = await this.azureService.isRemoteUpdateFT();
    this.drinkSetupFT = await this.azureService.isEnableDrinkSetupFT();
  }
}
