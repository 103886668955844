import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { TickerTapModel } from 'src/app/components/ticker-tap/ticker-tap-message-list/ticker-tap.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TickerTapService {
  cookieName = environment.cookieName;  
  public scheduleMessages: TickerTapModel[] = [];

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService
  ) { }

  getCurrentActiveTickerTapMessages() {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
	  };
    const parameter = {
		  timestamp: Date.now()
	  };
    var reqParameter = Object.assign(parameter, configHeader);
    return this.httpClient.get<any>(environment.apiUrl.event + '/api/v1/ticker-tap/currentMessages', reqParameter).pipe(map(response => {
      return response;
    }));
  }

  getTickerTapMessages(obj) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
	  };
    const parameter = {
      params: obj,
      timestamp: Date.now()
	  };
    var reqParameter = Object.assign(parameter, configHeader);
    return this.httpClient.get<any>(environment.apiUrl.event + '/api/v1/ticker-tap', reqParameter).pipe(map(response => {
      return response;
    }));
  }

  addUpdateTickerTapMessage(body) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.post<any>(environment.apiUrl.event + '/api/v1/ticker-tap/upsert', body, configHeader).pipe(map(response => {
      return response;
    }));
  }
}
