import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth/auth.service';
import { FAILURE_LOG_TYPES, METHOD_TYPES, PriceCardAction, SOXAuditLogsService } from '../services/sox-audit-logs/sox-audit-logs.service';
/*
  The JWT interceptor intercepts the incoming requests from the application/user and adds JWT token to the request's Authorization header,
  only if the user is logged in.
  This JWT token in the request header is required to access the SECURE END API POINTS on the server
*/

const logErrorCodes = [0, 500, 400, 404];

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  cookieName = 'CE_';
  authCookieName = environment.cookieName;

  constructor(
    private authService: AuthService,
    private soxAuditLogsService: SOXAuditLogsService,
    private spinner: NgxSpinnerService,
    private cookieService: CookieService,
    private msalService: MsalService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // handle any other requests which went unhandled
    const ignoreApiForIdealCheck = [
      environment.apiUrl.event + '/api/v1/livenotifications',
      environment.baseUrl + '/api/v1/account/refreshtoken',
    ];
    const authToken = this.cookieService.get(this.authCookieName + 'access_token');
    let requestHeaders = request.headers;
    requestHeaders = requestHeaders.append('b2baccess_token', this.cookieService.get(this.cookieName + 'b2baccess_token'));
    if (!!authToken && !requestHeaders.has('Authorization')) {
      requestHeaders = requestHeaders.append(
        'Authorization',
        'Bearer ' + this.cookieService.get(this.authCookieName + 'access_token'),
      );
    }
    const clonedRequest = request.clone({ headers: requestHeaders });
    return next.handle(clonedRequest).pipe(
      map((event: HttpEvent<any>) => {
        if (!ignoreApiForIdealCheck.includes(request.url)) {
          localStorage.setItem('LastUserActiveTime', new Date().getTime().toString());
        }
        if (event instanceof HttpResponse) {
          if (event.status === 205) {
            if (!this.authService.hasCalledSignoutLog) {
              this.authService.hasCalledSignoutLog = true;
              this.authService.logOut();
            }
            return null;
          }
          return event;
        }
      }),
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          this.spinner.hide();
          switch (err.status) {
            case 401:
              this.msalService.logoutRedirect();
              this.authService.removeAllCookies();
              localStorage.clear();
              return throwError(null);
            default:
              if (logErrorCodes.includes(err.status) && this.soxAuditLogsService.getlogTypeByUrl(request.url)) {
                this.createFailureLog(err, request);
              }
              return throwError(err);
          }
        } else {
          return throwError(null);
        }
      }),
    );
  }

  createFailureLog(err, request): void {
    let payload: any = {};
    if (request.url.includes('account/b2blogin') && err.status === 0) {
      payload.id_token = request.body.id_token;
      payload.statusCode = 502;
      payload.auditLogAction = METHOD_TYPES.PUT;
      this.soxAuditLogsService.addFailureLog(payload, FAILURE_LOG_TYPES.user).subscribe();
    } else if (this.soxAuditLogsService.getlogTypeByUrl(request.url) === FAILURE_LOG_TYPES.entity && request.url.includes('master/addmasterconfiguration')) {
      if (request.body) {
        payload = request.body;
      }
      payload.statusCode = (err.status) ? err.status : 502;
      payload.auditLogAction = METHOD_TYPES[request.method];
      this.soxAuditLogsService.addIngridentFailureLog(payload, this.soxAuditLogsService.getlogTypeByUrl(request.url)).subscribe();
    } else if (this.soxAuditLogsService.getlogTypeByUrl(request.url) === FAILURE_LOG_TYPES.user) {
      if (request.body) {
        payload = request.body;
      }
      if (request.method === 'PUT' || request.method === 'DELETE') {
        const parameters = request.url.split('/');
        if (this.soxAuditLogsService.isUUID(parameters[parameters.length - 1])) {
          payload.id = parameters[parameters.length - 1];
        } else {
          payload.id = null;
        }
      } else {
        payload.id = null;
      }
      payload.statusCode = (err.status) ? err.status : 502;
      payload.auditLogAction = METHOD_TYPES[request.method];
      this.soxAuditLogsService.addFailureLog(payload, this.soxAuditLogsService.getlogTypeByUrl(request.url)).subscribe();
    } else if (this.soxAuditLogsService.getlogTypeByUrl(request.url) === FAILURE_LOG_TYPES.entity) {
      if (request.body) {
        payload = request.body;
      }
      if (request.method === 'PUT' || request.method === 'DELETE') {
        const parameters = request.url.split('/');
        if (this.soxAuditLogsService.isUUID(parameters[parameters.length - 1])) {
          payload.id = parameters[parameters.length - 1];
        } else {
          payload.id = null;
        }
        if (request.method === 'DELETE' && request.url.includes('/drinkgrouptemplate/')) {
          payload.route = 'pc-templage';
        }
        if (request.url.includes('/deletepricecard/')) {
          payload.route = 'price-card';
        }
      }

      Object.keys(PriceCardAction).forEach(key => {
        if (request.url.toLowerCase().includes(key.toLowerCase())) {
          payload.priceCardAction = PriceCardAction[key];
        }
        payload.serviceName = 'Entity';
        payload.statusCode = (err.status) ? err.status : 502;
        payload.auditLogAction = METHOD_TYPES[request.method];
      });
      this.soxAuditLogsService.addFailureLog(payload, this.soxAuditLogsService.getlogTypeByUrl(request.url)).subscribe();

    } else if (this.soxAuditLogsService.getlogTypeByUrl(request.url) === FAILURE_LOG_TYPES.machine) {

      let unrecognized = false;

      if (request.url.includes('machine/assignmarkettounrecognized')) {
        unrecognized = true;
      }
      if (request.body) {
        payload = request.body;
      }
      if (request.method === 'PUT' && unrecognized === false) {
        const parameters = request.url.split('/');
        if (this.soxAuditLogsService.isUUID(parameters[parameters.length - 1])) {
          payload.id = parameters[parameters.length - 1];
        } else {
          payload.id = null;
        }
      }
      payload.serviceName = 'Machine';
      payload.statusCode = (err.status) ? err.status : 502;
      payload.auditLogAction = METHOD_TYPES[request.method];
      this.soxAuditLogsService.addFailureLog(payload, this.soxAuditLogsService.getlogTypeByUrl(request.url)).subscribe();
    } else if (this.soxAuditLogsService.getlogTypeByUrl(request.url) === FAILURE_LOG_TYPES.salesProcessor) {
      if (request.method === 'POST' && (
        request.url.includes('/updatedrinkdiscrepancy')
        || request.url.includes('/updatecupdiscrepancy')
        || request.url.includes('/updateconfigurationdiscrepancy')
        || request.url.includes('/updatepricecarddiscrepancy')
      )) {

        payload = request.body;
        payload.statusCode = (err.status) ? err.status : 502;
      }
      this.soxAuditLogsService.addFailureLog(payload, this.soxAuditLogsService.getlogTypeByUrl(request.url)).subscribe();
    }
  }
}
