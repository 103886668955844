import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NotificationCountService {

  private notificationsource = new BehaviorSubject<string>('');
  telecast = this.notificationsource.asObservable();

  constructor() { }

  updateCount(count) {
    this.notificationsource.next(count);
  }
}
