import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AzureService } from '../azure/azure.service';
import { ResponseGetMarketLowAdminSetup } from './dtos/market-low-admin-setup-response';

@Injectable({
	providedIn: 'root'
})

export class EntityService {
	cookieName = environment.cookieName;
	daysDisplayName = {
		0: 'Monday',
		1: 'Tuesday',
		2: 'Wednesday',
		3: 'Thursday',
		4: 'Friday',
		5: 'Saturday',
		6: 'Sunday'
	}
	selectedCloneDays = [];
	selected_slot_index = -1;
	cloneDrawerfilter = false;
	tradehours = [];
	entityExistingData = {
		'level-1': { 'tab-0': {}, 'tab-1': {}, 'tab-2': { '0': {}, '1': {}, '2': {}, '3': {} } },
		'level-2': { 'tab-0': {}, 'tab-1': {}, 'tab-2': {} },
		'level-3': { 'tab-0': {}, 'tab-1': {}, 'tab-2': {} },
		'level-3.5': { 'tab-0': {}, 'tab-1': {}, 'tab-2': {} },
		'level-4': { 'tab-0': {}, 'tab-1': { '0': {}, '1': {}, '2': {}, '3': {} } },
		'level-5': { 'tab-0': {}, 'tab-1': {}, 'tab-2': {} },
		'level-6': { 'tab-0': {}, 'tab-1': {}, 'tab-2': {} },
		'level-7': { 'tab-0': {}, 'tab-1': {}, 'tab-2': { '0': {}, '1': {}, '2': {}, '3': {} } },
		'level-8': { 'tab-0': {}, 'tab-1': {}, 'tab-2': { '0': {}, '1': {}, '2': {}, '3': {} } }
	}
	entityLatestData = {
		'level-1': { 'tab-0': {}, 'tab-1': {}, 'tab-2': { '0': {}, '1': {}, '2': {}, '3': {} } },
		'level-2': { 'tab-0': {}, 'tab-1': {}, 'tab-2': {} },
		'level-3': { 'tab-0': {}, 'tab-1': {}, 'tab-2': {} },
		'level-3.5': { 'tab-0': {}, 'tab-1': {}, 'tab-2': {} },
		'level-4': { 'tab-0': {}, 'tab-1': { '0': {}, '1': {}, '2': {}, '3': {} } },
		'level-5': { 'tab-0': {}, 'tab-1': {}, 'tab-2': {} },
		'level-6': { 'tab-0': {}, 'tab-1': {}, 'tab-2': {} },
		'level-7': { 'tab-0': {}, 'tab-1': {}, 'tab-2': { '0': {}, '1': {}, '2': {}, '3': {} } },
		'level-8': { 'tab-0': {}, 'tab-1': {}, 'tab-2': { '0': {}, '1': {}, '2': {}, '3': {} } }
	}
	nextTabIndex: any = -1;
	powerbiReportPreloaded = false;
	isValidatedTradehoursTab = false;
	isValidatedMachineModelTab = false;
	saveMachineModelDetails = false;
	timeZoneValue = '';
	addEntityOnHoldData = {
		'level-7': { 'tab-0': {}, 'tab-1': {} },
		'level-8': { 'tab-0': {}, 'tab-1': {} }
	}
	marketHasBillToField = false;
	marketHasSellToField = false;

	constructor(
		private cookieService: CookieService,
		private httpClient: HttpClient,
		private azureService: AzureService
	) { }

	resetAddEntityOnHoldData() {
		this.isValidatedTradehoursTab = false;
		this.isValidatedMachineModelTab = false;
		this.saveMachineModelDetails = false;
		this.addEntityOnHoldData = {
			'level-7': { 'tab-0': {}, 'tab-1': {} },
			'level-8': { 'tab-0': {}, 'tab-1': {} }
		};
	}
	getFormsFileds(levelNumber, parentEntityId, marketId) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		const parameter = {
			params: {
				'levelNumber': levelNumber,
				'parentEntityId': parentEntityId,
				'marketId': marketId,
				'machineType': 'Schaerer',
				'pageNo': '1',
				'pageSize': '1000'
			},
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/master/form', reqParameter).pipe(map(response => {
			return response;
		}));
	}
	getEntityDetailsWithForm(entityId, parentEntityId, moduleAction = '') {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};

		if (parentEntityId == '') {
			return this.httpClient.get<any>(environment.baseUrl + '/api/v1/entity/' + entityId + '/' + moduleAction, configHeader).pipe(map(response => {
				return response;
			}));
		}
		else {
			return this.httpClient.get<any>(environment.baseUrl + '/api/v1/entity/' + entityId + '/' + moduleAction + '/' + parentEntityId, configHeader).pipe(map(response => {
				return response;
			}));
		}
	}
	getLookup(route) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.get<any>(environment.baseUrl + route, configHeader).pipe(map(response => {
			return response;
		}));
	}
	getLanguageLookup() {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/master/language', configHeader).pipe(map(response => {
			return response;
		}));
	}
	getSuggestion(route, queryParams) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		const parameter = {
			params: {
				'search': queryParams.search,
				'fieldUniqueName': queryParams.fieldUniqueName
			},
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.baseUrl + route, reqParameter).pipe(map(response => {
			return response;
		}));
	}
	getSalesEmbbedReport() {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/salesembbedreport', configHeader).pipe(map(response => {
			return response;
		}));
	}
	getMarketLookup(machineId = '', propositionTypeIds = '') {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		const parameter = {
			params: {
				'machineId': machineId,
				'propositionTypeIds': propositionTypeIds
			},
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/master/market', reqParameter).pipe(map(response => {
			return response;
		}));
	}
	getParentEntityLookup(queryParams) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		const parameter = {
			params: {
				'MarketId': queryParams.marketId,
				'EntityId': queryParams.entityId,
				'levelNumber': queryParams.levelNumber
			},
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/master/parententity', reqParameter).pipe(map(response => {
			return response;
		}));
	}
	getPartnerLookup(marketId) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		const parameter = {
			params: {
				'parentEntityId': marketId
			},
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/master/partner', reqParameter).pipe(map(response => {
			return response;
		}));
	}
	getPartnerByMarketAndPropositionLookup(marketId: string, propositions: string[]) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		const parameter = {
			params: {
				'parentEntityId': marketId,
				'propositionTypeIds': propositions
			}
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.baseUrl + '/api/v2/master/partner', reqParameter).pipe(map(response => {
			return response;
		}));
	}
	getSiteLookup(parnterId, isActiveSiteOnly?) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		const parameter = {
			params: {
				'parentEntityId': parnterId,
				'isActiveSiteOnly': isActiveSiteOnly ?? false
			},
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/master/site', reqParameter).pipe(map(response => {
			return response;
		}));
	}
	getMachineLookup(siteIds) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.post<any>(environment.baseUrl + '/api/v1/master/getmachinesbysites', siteIds, configHeader).pipe(map(response => {
			return response;
		}));
	}
	getEntityList(filters) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		const parameter = {
			params: filters,
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/entity', reqParameter).pipe(map(response => {
			return response;
		}));
	}
	getEntityListV2(filters) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		const parameter = {
			params: filters,
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/entity/v2', reqParameter).pipe(map(response => {
			return response;
		}));
	}

	getByLevel(filters) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		const parameter = {
			params: filters,
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.baseUrl + '/api/v2/entity/getbylevel', reqParameter);
	}

	getEntityMoveList(filters) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		const parameter = {
			params: filters,
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/moveentity', reqParameter).pipe(map(response => {
			return response;
		}));
	}

	async addEntity(body) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		let baseUrl = environment.apiUrl.entity + '/api/v1/entity';

		if (body.levelNumber == 8) {
			baseUrl = environment.apiUrl.machine + '/api/v1/machine';

			const shouldUseAddMachineV2 = await this.azureService.isUseAddMachineV2FTEnabled();

			if(shouldUseAddMachineV2){
				baseUrl = environment.apiUrl.machine + '/api/v2/machine';
			}
		}
		else
		{
			const shouldUseAddEntityV2 = await this.azureService.isAddEntityV2Enabled();

			if (shouldUseAddEntityV2) {
				baseUrl = environment.apiUrl.entity + '/api/v2/entity';
			}
		}

		return this.httpClient.post<any>(baseUrl, body, configHeader).pipe(map(response => {
			return response;
		}));
	}
	addEntityValidate(body) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		let baseUrl = environment.apiUrl.entity + '/api/v1/addsitevalidator';
		if (body.levelNumber == 8) {
			baseUrl = environment.apiUrl.machine + '/api/v1/machine/validateaddmachine';
		}
		return this.httpClient.post<any>(baseUrl, body, configHeader).pipe(map(response => {
			return response;
		}));
	}
	async updateEntity(body, entityId) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};

		let baseUrl = environment.apiUrl.entity + '/api/v1/entity/';

		if (body.levelNumber == 8) {
			baseUrl = environment.apiUrl.machine + '/api/v1/machine/';

			const shouldUseAddMachineV2 = await this.azureService.isUseAddMachineV2FTEnabled();

			if(shouldUseAddMachineV2){
				baseUrl = environment.apiUrl.machine + '/api/v2/machine/';
			}
		}
		else
		{
			const shouldUseAddEntityV2 = await this.azureService.isAddEntityV2Enabled();

			if (shouldUseAddEntityV2) {
				baseUrl = environment.apiUrl.entity + '/api/v2/entity/';
			}
		}

		return this.httpClient.put<any>(baseUrl + entityId, body, configHeader).pipe(map(response => {
			return response;
		}));
	}
	updateEntityStatus(entityId) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.put<any>(environment.baseUrl + '/api/v1/entity/status/' + entityId, {}, configHeader).pipe(map(response => {
			return response;
		}));
	}
	checkEntityStatusValidation(status, parentEntityId, entityId, levelNumber) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		if (levelNumber != 8) {
			const parameter = {
				params: {
					parentEntityId: parentEntityId,
					id: entityId
				},
				timestamp: Date.now()
			};
			var reqParameter = Object.assign(parameter, configHeader);
			return this.httpClient.get<any>(environment.baseUrl + '/api/v1/entity/statusValidation/' + status, reqParameter).pipe(map(response => {
				return response;
			}));
		} else {
			const parameter = {
				params: {
					entityId: parentEntityId,
					id: entityId
				},
				timestamp: Date.now()
			};
			var reqParam = Object.assign(parameter, configHeader);
			return this.httpClient.get<any>(environment.apiUrl.machine + '/api/v1/machine/statusValidation/' + status, reqParam).pipe(map(response => {
				return response;
			}));
		}
	}
	addMarketSetup(body) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.post<any>(environment.baseUrl + '/api/v1/marketsetup', body, configHeader).pipe(map(response => {
			return response;
		}));
	}
	getMarketSetup(marketId) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/marketsetup/' + marketId, configHeader).pipe(map(response => {
			return response;
		}));
	}
	getEntityStatusLogs(entityId, levelNumber) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		const parameter = {
			params: {
				entityId: entityId,
				levelNumber: levelNumber,
			},
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/entity/statuslog', reqParameter).pipe(map(response => {
			return response;
		}));
	}
	getSiteTradeHours(siteId) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/tradehours/' + siteId, configHeader).pipe(map(response => {
			return response;
		}));
	}
	getActiveMachineCount(siteId) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/entity/machineCount/' + siteId, configHeader).pipe(map(response => {
			return response;
		}));
	}
	addSiteTradeHours(body) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.post<any>(environment.baseUrl + '/api/v1/tradehours', body, configHeader).pipe(map(response => {
			return response;
		}));
	}
	getmasterConfiguration(body) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.post<any>(environment.baseUrl + '/api/v1/master/masterConfiguration', body, configHeader).pipe(map(response => {
			return response;
		}));
	}

	addmasterConfiguration(body) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.post<any>(environment.baseUrl + '/api/v1/master/addmasterconfiguration', body, configHeader).pipe(map(response => {
			return response;
		}));
	}

	getIngredientcupsize(marketId, entityId, machineId, parentLevelNumber, levelNumber, isAddRequest = false, parentEntityId = '', propositionTypeId = '', propositionType = '') {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		var url = '';
		if (marketId == null && entityId == null && machineId == null && parentLevelNumber == null && levelNumber == null) {
			url = '/api/v1/ingredientcupsize';
		}
		else {
			url = '/api/v1/ingredientcupsize?marketId=' + marketId + '&parentLevelNumber=' + parentLevelNumber + '&levelNumber=' + levelNumber;
			if (entityId != '' && entityId != null) {
				url = url + '&entityId=' + entityId;
			}
			if (machineId != '' && machineId != null) {
				url = url + '&machineId=' + machineId;
			}
			if (propositionTypeId != '' && propositionTypeId != null) {
				url = url + '&propositionTypeId=' + propositionTypeId;
			}
			if (propositionType != '' && propositionType != null) {
				url = url + '&propositionType=' + propositionType;
			}
		}
		url = url + '&isAddRequest=' + isAddRequest + '&parentEntityId=' + parentEntityId;
		return this.httpClient.get<any>(environment.baseUrl + url, configHeader).pipe(map(response => {
			return response;
		}));
	}

	getIngredientcolumns(machineTypeId, marketId, entityId, machineId, parentLevelNumber, levelNumber, isAddRequest = false, parentEntityId = '', machineModelId = '', propositionTypeId = '', propositionType = '') {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		var url = '/api/v1/ingredientcolumns?marketId=' + marketId + '&parentLevelNumber=' + parentLevelNumber + '&levelNumber=' + levelNumber;
		if (entityId != null && entityId != '') {
			url = url + '&entityId=' + entityId;
		}
		if (machineTypeId != null && machineTypeId != '') {
			url = url + '&machineTypeId=' + machineTypeId;
		}
		if (machineId != null && machineId != '') {
			url = url + '&machineId=' + machineId;
		}
		if (propositionTypeId != null && propositionTypeId != '') {
			url = url + '&propositionTypeId=' + propositionTypeId;
		}
		if (propositionType != '' && propositionType != null) {
			url = url + '&propositionType=' + propositionType;
		}
		url = url + '&isAddRequest=' + isAddRequest + '&parentEntityId=' + parentEntityId + '&machineModelId=' + machineModelId;
		return this.httpClient.get<any>(environment.baseUrl + url, configHeader).pipe(map(response => {
			return response;
		}));
	}

	saveIngredientMaster(body) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};

		return this.httpClient.post<any>(environment.baseUrl + '/api/v1/saveingredientmaster', body, configHeader).pipe(map(response => {
			return response;
		}));
	}

	saveIngredientSetup(body) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};

		return this.httpClient.post<any>(environment.baseUrl + '/api/v1/saveingredientsetup', body, configHeader).pipe(map(response => {
			return response;
		}));
	}

  getLowAdminProposition(entityId, propositionTypeId, level = null) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		const parameter = {
			params: {
				'entityId': entityId,
				'propositionTypeId': propositionTypeId,
				'levelNumber': level
			},
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<ResponseGetMarketLowAdminSetup>(environment.baseUrl + '/api/v1/lowadmin', reqParameter);
	}

	getIngredientMasters(marketId) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};

		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/ingredientmaster?marketId=' + marketId, configHeader).pipe(map(response => {
			return response;
		}));
	}

	getMachineType() {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};

		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/master/machine/getmachinetype', configHeader).pipe(map(response => {
			return response;
		}));
	}
	getMarketMachineType(marketId) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/master/machine/getmarketmachinetype/' + marketId, configHeader).pipe(map(response => {
			return response;
		}));
	}

	machineGroupDisableValidation(marketId, machineGroupId) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/machineGroupDisableValidation/' + marketId + '/' + machineGroupId, configHeader).pipe(map(response => {
			return response;
		}));
	}

	cupTypeDisableValidation(requestModel) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};

		const parameter = {
			params: requestModel,
			timestamp: Date.now()
		};

		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/cupTypeDisableValidation/', reqParameter).pipe(map(response => {
			return response;
		}));
	}

	getMachineData(machineId) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.get<any>(environment.apiUrl.machine + '/api/v1/machineDetail/' + machineId, configHeader).pipe(map(response => {
			return response;
		}));
	}

	getEntityMachineCount(filters) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		const parameter = {
			params: filters,
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/entity/entitymachinecount', reqParameter).pipe(map(response => {
			return response;
		}));
	}

	getEntityHierarchy(body) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		const parameter = {
			params: body,
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.apiUrl.entity + '/api/v1/entity/entityhierarchy', reqParameter).pipe(map(response => {
			return response;
		}));
	}

	getAdvanceSearchFilters() {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};

		return this.httpClient.get<any>(environment.apiUrl.entity + '/api/v1/entity/getadvancesearchfilters', configHeader).pipe(map(response => {
			return response;
		}));
	}

	removeMarketFieldVisibilityStorage() {
		localStorage.removeItem('moveEntityHasPartnerEntityBillTo');
		localStorage.removeItem('moveEntityHasSiteEntitySellTo');
	}

	getEntityFiltersByPreviousEntity(body) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};

		return this.httpClient.post<any>(environment.apiUrl.entity + '/api/v1/master/getentityfiltersbypreviousentity', body, configHeader).pipe(map(response => {
			return response;
		}));

	}
	getCupTypePriority() {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};

		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/master/getcuptypepriority', configHeader).pipe(map(response => {
			return response;
		}));
	}
	updateCupTypePriority(body) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		let baseUrl = environment.apiUrl.entity + '/api/v1/master/updatecuptypepriority';

		return this.httpClient.put<any>(baseUrl, body, configHeader).pipe(map(response => {
			return response;
		}));
	}

	getEntityPropositions(entityId) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.get<any>(environment.apiUrl.entity + '/api/v1/entitypropositions/' + entityId, configHeader).pipe(map(response => {
			return response;
		}));
	}

	validatePropositionDisableforEntity(requestModel) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		const parameter = {
			params: requestModel,
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.apiUrl.entity + '/api/v1/validatepropositiondisableforentity', reqParameter).pipe(map(response => {
			return response;
		}));
	}
}
