import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { RoleService } from 'src/app/services/role/role.service';
import { UserAuditService } from 'src/app/services/user-audit/user-audit.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-user-audit-list',
  templateUrl: './user-audit-list.component.html',
  styleUrls: ['./user-audit-list.component.css']
})
export class UserAuditListComponent implements OnInit {

  public cookieName = environment.cookieName;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['lastName','firstName', 'email','companyName','userRole','marketName','entityLevel','accountStatus','accountCreated','lastSignInDateTime','auditorComment','isApproved'];
  dataSource: any = [];
  @ViewChild(MatSort) sort: MatSort;
  userAuditList: any = []
  selectedFiled: any;
  label = "User";
  recordsTotal = 0;  
  dialogRef: MatDialogRef<any>;
  isPageDataLoad = false;
  isUserDataLoad = false;

  isVisibleStartButton = true;
  infoMessage = "";
  @ViewChild("confirmDialogTemplate") confirmDialogTemplate: TemplateRef<any>;
  hasPendingAuditUsersCount = 0;
  nextRoute = '';
  dialogTexts = {
    successTitle: '',
    cancelTitle: '',
    message: '',
    deviceName: '',
    status: '',
    title: ''
  }
  isAuditCycleCompleted = 0;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private userService: UserService, 
    private userAuditService: UserAuditService, 
    public dialog: MatDialog, 
    private toaster: Toaster,
    public roleService:RoleService) {
  }
  ngOnInit() {
    if(!this.roleService.objRole.isUserAuditorGRID && !this.roleService.objRole.isUserAuditorGRIDR && !this.roleService.objRole.isUserAuditorLevel1 && !this.roleService.objRole.isUserAuditorLevel1R && !this.roleService.objRole.isUserAuditorLevel2 && !this.roleService.objRole.isUserAuditorLevel2R && !this.roleService.objRole.isUserAuditorLevel3 && !this.roleService.objRole.isUserAuditorLevel3R) {
      this.router.navigate(['/unauthorized']);
    }
    localStorage.removeItem("isAuditInProgress");
    this.getStartVisible();    
  } 
  startaudit(){
    this.spinner.show();
    this.userAuditService.startaudit().subscribe(response => { 
      localStorage.setItem("isAuditInProgress", 'true');
      this.isVisibleStartButton = false;
      if(response.isVisibleStartButton || response.auditAlreadyInProcess){
        localStorage.setItem("isAuditInProgress", 'true');
        this.getAuditList();
      }else if(!response.isVisibleStartButton && !response.auditAlreadyInProcess){
        this.infoMessage = response.message;
        this.isUserDataLoad = true;
        this.spinner.hide();  
      }
      this.isPageDataLoad = true;
        
    },err =>{
      this.roleService.showErrorMsg(err);
    });
  }
  stopaudit(nextRoute){
    this.userAuditService.stopaudit().subscribe(response => { 
      localStorage.removeItem("isAuditInProgress");
        if(nextRoute){
          this.router.navigate([nextRoute]);
        }else{
          this.router.navigate(['/user-audit']);
        }      
    },err =>{
      this.roleService.showErrorMsg(err);
    });
  }
  getStartVisible(){
    this.spinner.show();
    this.userAuditService.getStartaudit().subscribe(response => {
      this.isVisibleStartButton = response.isVisibleStartButton;
      this.isAuditCycleCompleted = response.isAuditCycleCompleted;
      if (this.isVisibleStartButton && this.isAuditCycleCompleted) {
        this.spinner.hide(); 
      } else {
        if(!response.isVisibleStartButton && response.auditAlreadyInProcess){
          localStorage.setItem("isAuditInProgress", 'true');
          this.getAuditList();
        }else if(!response.isVisibleStartButton && !response.auditAlreadyInProcess){        
          this.infoMessage = response.message;
          this.isUserDataLoad = true;
          this.userAuditList = [];
          this.spinner.hide();      
        }else{
          this.userAuditList = [];
          this.spinner.hide();
        }
    }
      this.isPageDataLoad = true;      
    },err =>{
      this.roleService.showErrorMsg(err);
    });
  }
  onCommentChange(i){
    if(this.userAuditList[i]['auditorComment']){
      this.userAuditList[i]['isApproved'] = false;
    }    
  }
  submtiAudits(){
    let hasAuditData = this.userAuditList.some(el => el.isProcessed === false);
    let hasSingleUpdatedData = this.userAuditList.some(el => el.isProcessed === false && (el.isApproved === true || (el.auditorComment != null && el.auditorComment != '')));
    let hasInValidData = this.userAuditList.some(el => el.isProcessed === false && (el.auditorComment && el.auditorComment.length > 250));
    if (!this.userAuditList.length || !hasAuditData || !hasSingleUpdatedData || hasInValidData) {
      let msg = "";
      if(!this.userAuditList.length || !hasAuditData){
        msg = 'All users are already audited';
      }else if(hasInValidData){
        msg = 'Please provide valid input for all the highlighted fields';
      }else if(!hasSingleUpdatedData){
        msg = 'Please approve or add comment for at least a single user';
      }else{
        msg = 'Please provide valid input for all the highlighted fields';
      }
      this.toaster.open({
        text: msg,
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    }else{
      this.spinner.show();
      let updatedUserData:any = this.userAuditList.filter(el => el.isProcessed === false && (el.isApproved === true || (el.auditorComment != null && el.auditorComment != '')));
      let body = [];
      let i = 1;
      updatedUserData.forEach(element => {
        body.push({auditorComment:element.auditorComment,isApproved:element.isApproved,userAuditStatusId:element.userAuditStatusId});
        if(i == updatedUserData.length){
          this.userAuditService.submitAudit({userAudits:body,isAuditedAllUsers:(updatedUserData.length == this.hasPendingAuditUsersCount)}).subscribe(response => {
            this.userAuditList = [];
            response.displayMessages.forEach(msg => {
              this.roleService.showToasterMsg(msg,'success');
            });
            this.spinner.hide();
            this.isPageDataLoad = false;
            this.isUserDataLoad = false;
            this.isVisibleStartButton = true;   
            this.getStartVisible();
            localStorage.removeItem("isAuditInProgress");
          },err =>{
            this.roleService.showErrorMsg(err);
          });
        }
        i++;
      });
     }   
  }
  getAuditList() {
    this.spinner.show();
    this.userAuditService.getAuditList().subscribe(response => {
     this.recordsTotal = response?.params?.count;
     if (response && response['data']) {
          this.userAuditList = response['data'];
          let pendigUsersAuditData = this.userAuditList.filter(el => el.isProcessed === false);
          this.hasPendingAuditUsersCount = pendigUsersAuditData.length;
     } else {
          this.userAuditList = [];
     }
     this.isUserDataLoad = true;
     setTimeout(() => {
      if (this.userAuditList.length > 0) {
        var allComments: any = document.getElementsByTagName('textarea');
        if (allComments.length > 0) {
          for (let index = 0; index < allComments.length; index++) {
              if (allComments[index]?.textLength > 0) {
                allComments[index].style.maxHeight = 1 + allComments[index].scrollHeight + "px";
                allComments[index].style.minHeight = 1 + allComments[index].scrollHeight + "px";
              }            
          }
        }
      }
     }, 300);
    
     this.spinner.hide();
    }, err => {
      this.roleService.showErrorMsg(err);
    });    
  }  
  onConfirm() {
    this.dialogRef.close();
    localStorage.removeItem("isUserEdit");
    this.stopaudit(this.nextRoute);    
  }
  
  async canDeactivate(nextRoute): Promise<void> {
    this.nextRoute = nextRoute;
    this.getConfirmationBox();
  }
  getConfirmationBox() {
    let msg = 'All unsaved changes will be lost. Are you sure you want to exit?';
    this.dialogTexts = {
        successTitle: 'Yes',
        cancelTitle: 'No',
        message: msg,
        deviceName: '',
        status: '',
        title: 'Confirmation',        
    }    
    const eventPopupModel = new MatDialogConfig();
    eventPopupModel.height = 'auto';
    eventPopupModel.width = '670px';
    eventPopupModel.disableClose = true;
    this.dialogRef = this.dialog.open(this.confirmDialogTemplate, eventPopupModel);    
  }

  onCancel() {
    this.dialogRef.close();
  }

  adjustCommentBoxHeight(event) {
    var textAreaControl = event.target;
    textAreaControl.style.minHeight = "1px";
    textAreaControl.style.minHeight = (textAreaControl.scrollHeight > 220 ? 220 : (1 + textAreaControl.scrollHeight)) +"px";
  }
}


