import { Component, Input, OnInit } from '@angular/core';
import { Toast } from 'ngx-toast-notifications';

@Component({
  selector: 'app-custom-toast',
  templateUrl: './custom-toast.component.html',
  styleUrls: ['./custom-toast.component.css']
})
export class CustomToastComponent implements OnInit {
  @Input() toast: Toast;
  constructor() { }

  ngOnInit(): void {
  }

}
